import { AppBar as MuiAppBar } from '@mui/material';
import { styled } from '@mui/material/styles';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
    background: `linear-gradient(to bottom, ${theme.palette.background.default} 0%, transparent 80%)`,
    backdropFilter: 'blur(1.2px)', // Higher blur to give a stronger effect
    transition: 'all 0.5s cubic-bezier(.04,.62,.84,.5)',
    boxShadow: 'none',
    '&.MuiAppBar-root': {
        boxShadow: 'none',
    },
}));

export default AppBar;
