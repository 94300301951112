'use client';

import React from 'react';
import { Typography, Box } from '@mui/material';
import { COPYWRITE_TEXT } from '@/config/constants';
import { useTheme } from '@/hooks/useTheme';

const Footer: React.FC = () => {
  const { theme } = useTheme();
  return (
    <Box component="footer" sx={{
      py: '3rem', textAlign: 'center', display: 'flex', justifyContent: 'center', backgroundColor: theme.palette.background.default
    }}>
      <Typography variant="body2">{COPYWRITE_TEXT}</Typography>
    </Box>
  );
};

export default Footer;


