import React from "react";
import { Box, Button, Fade } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { NavItem } from "@/types/navigation";

interface DesktopNavProps {
    navItems: NavItem[];
    customTheme: Theme;
    fadeDuration: number;
    staggerDelay: number;
}

const DesktopNav: React.FC<DesktopNavProps> = ({ navItems, customTheme, fadeDuration, staggerDelay }) => {
    const scrollToSection = (sectionId: string) => {
        const section = document.querySelector(sectionId);
        if (section) {
            section.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    return (
        <Box component="nav" sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            {navItems.map((item, index) => (
                <Fade
                    key={item.path}
                    in={true}
                    timeout={fadeDuration}
                    style={{ transitionDelay: `${index * staggerDelay}ms` }}
                >
                    <Button
                        onClick={() => scrollToSection(item.path)}
                        sx={{
                            marginRight: customTheme.spacing(2),
                            color: customTheme.palette.text.primary,
                            backdropFilter: 'blur(10px)',
                            backgroundColor: customTheme.palette.background.paper + '35',
                            padding: customTheme.spacing(1, 3),
                            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                            '&:hover': {
                                backgroundColor: customTheme.palette.background.paper,
                                boxShadow: `0 1px 3px ${customTheme.palette.action.hover}`,
                            },
                        }}
                    >
                        {item.label}
                    </Button>
                </Fade>
            ))}
        </Box>
    );
};

export default DesktopNav;