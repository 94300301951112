import React from "react";
import { Drawer, List, ListItemButton, ListItemText, Box, Fade, IconButton } from "@mui/material";
import Link from "next/link";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import { Theme } from "@mui/material/styles";
import { NavItem } from "@/types/navigation";
import { usePathname } from 'next/navigation';

interface MobileNavProps {
    drawerOpen: boolean;
    toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
    navItems: NavItem[];
    mode: "light" | "dark";
    toggleTheme: () => void;
    customTheme: Theme;
    fadeDuration: number;
    staggerDelay: number;
}

const MobileNav: React.FC<MobileNavProps> = ({
    drawerOpen,
    toggleDrawer,
    navItems,
    mode,
    toggleTheme,
    customTheme,
    fadeDuration,
    staggerDelay
}) => {
    const pathname = usePathname();
    const isHomePage = pathname === '/';

    return (
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <Box
                sx={{
                    width: 250,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    backgroundColor: customTheme.palette.background.paper,
                }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
            >
                <List>
                    {!isHomePage ? (
                        <ListItemButton
                            component={Link}
                            href="/"
                            onClick={toggleDrawer(false)}
                            sx={{
                                '&:hover': {
                                    backgroundColor: customTheme.palette.action.hover,
                                },
                            }}
                        >
                            <HomeIcon sx={{ mr: 1 }} />
                            <ListItemText primary="Home" />
                        </ListItemButton>
                    ) : navItems.map((item: NavItem, index: number) => (
                        <Fade
                            key={item.path}
                            in={true}
                            timeout={fadeDuration}
                            style={{ transitionDelay: `${index * staggerDelay}ms` }}
                        >
                            <ListItemButton
                                component={Link}
                                href={item.path}
                                onClick={toggleDrawer(false)}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: customTheme.palette.action.hover,
                                    },
                                }}
                            >
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        </Fade>
                    ))}
                </List>
                <Box
                    sx={{
                        padding: customTheme.spacing(2),
                        display: 'flex',
                        justifyContent: 'center',
                        gap: customTheme.spacing(1),
                    }}
                >
                    <IconButton
                        onClick={toggleTheme}
                        sx={{
                            color: customTheme.palette.text.primary,
                            border: `1px solid ${customTheme.palette.divider}`,
                            borderRadius: '50%',
                            padding: customTheme.spacing(1),
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                                backgroundColor: customTheme.palette.action.hover,
                            },
                        }}
                        aria-label="toggle theme"
                    >
                        {mode === "dark" ? (
                            <Brightness7Icon sx={{ fontSize: 20, strokeWidth: 1 }} />
                        ) : (
                            <Brightness4Icon sx={{ fontSize: 20, strokeWidth: 1 }} />
                        )}
                    </IconButton>
                    <IconButton
                        component={Link}
                        href="/settings"
                        sx={{
                            color: customTheme.palette.text.primary,
                            border: `1px solid ${customTheme.palette.divider}`,
                            borderRadius: '50%',
                            padding: customTheme.spacing(1),
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                                backgroundColor: customTheme.palette.action.hover,
                            },
                        }}
                        aria-label="settings"
                    >
                        <SettingsIcon sx={{ fontSize: 20, strokeWidth: 1 }} />
                    </IconButton>
                </Box>
            </Box>
        </Drawer>
    );
};

export default MobileNav;
