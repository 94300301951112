'use client';
import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@/hooks/useTheme';

const PatternBackground: React.FC = () => {
  const { theme } = useTheme();

  const patternColor1 = theme.palette.primary.main;
  const patternColor2 = theme.palette.secondary.main;
  const backgroundColor = theme.palette.background.default;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
        backgroundColor: backgroundColor,
        backgroundImage: `
          linear-gradient(30deg, ${patternColor1} 12%, transparent 12.5%, transparent 87%, ${patternColor1} 87.5%, ${patternColor1}),
          linear-gradient(150deg, ${patternColor1} 12%, transparent 12.5%, transparent 87%, ${patternColor1} 87.5%, ${patternColor1}),
          linear-gradient(30deg, ${patternColor1} 12%, transparent 12.5%, transparent 87%, ${patternColor1} 87.5%, ${patternColor1}),
          linear-gradient(150deg, ${patternColor1} 12%, transparent 12.5%, transparent 87%, ${patternColor1} 87.5%, ${patternColor1}),
          linear-gradient(60deg, ${patternColor2} 25%, transparent 25.5%, transparent 75%, ${patternColor2} 75%, ${patternColor2}),
          linear-gradient(60deg, ${patternColor2} 25%, transparent 25.5%, transparent 75%, ${patternColor2} 75%, ${patternColor2})
        `,
        backgroundSize: '80px 140px',
        backgroundPosition: '0 0, 0 0, 40px 70px, 40px 70px, 0 0, 40px 70px',
        opacity: 0.1,
      }}
    />
  );
};

export default PatternBackground;