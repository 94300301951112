'use client';

import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@/hooks/useTheme';

const GradientBackground: React.FC = () => {
  const { theme } = useTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        // '&::after': {
        //   content: '""',
        //   position: 'absolute',
        //   top: 0,
        //   left: 0,
        //   width: '100%',
        //   height: '100%',
        //   background: `linear-gradient(to bottom, ${theme.palette.background.default}80, ${theme.palette.background.paper}10)`,
        // },
        zIndex: -1,
      }}
    />
  );
};

export default GradientBackground;


