'use client';

import React from 'react';
import Script from 'next/script';
import { GoogleAnalytics } from '@next/third-parties/google';
import { ConfigService } from '@/services/configService';

const configService = new ConfigService();
const { facebookPixelId, googleAnalyticsId } = configService.getCurrentConfig();

export const TrackingScripts: React.FC = () => {
  return (
    <>
      {facebookPixelId && (
        <Script id="facebook-pixel" strategy="afterInteractive">
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${facebookPixelId}');
          fbq('track', 'PageView');
        `}
        </Script>
      )}
      {googleAnalyticsId && <GoogleAnalytics gaId={googleAnalyticsId} />}
    </>
  );
};

export default TrackingScripts;