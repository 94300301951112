export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || 'https://sabasmendivil.com';
export const SITE_NAME = 'Sabás Mendívil';
export const SITE_DESCRIPTION = 'Personal website of Sabás Mendívil, a software engineer passionate about creating innovative solutions.';
export const TWITTER_HANDLE = '@sabasmendivil';
export const COPYWRITE_TEXT = `© ${new Date().getFullYear()} Sabás Mendívil. All rights reserved.`;

export const NAV_ITEMS = [
  // { label: 'Home', path: '#home' },
  { label: 'Skills', path: '#skills' },
  { label: 'Projects', path: '#projects' },
  { label: 'About', path: '#about' },
  { label: 'Contact', path: '#contact' },
];